/*
using color palette spotify uses, from https://blog.karenying.com/posts/50-shades-of-dark-mode-gray
*/

#root {
  margin: 0;
  background-color: #121212;
  color: #FFFFFF;
}

.secondary {
  color: #B3B3B3;
}

.contestant > img {
  width: auto;
  max-height: 300px;
  max-width: 300px;
}

.eliminated {
  color: #E55454;
}
